@font-face {
    font-family: "SFPRO";
    src: url(../fonts/SFPRODISPLAYREGULAR.OTF) format("truetype");
  }

.tentangkami {
    background-color: rgba(204, 204, 204, 0.5);
    padding: 30px;
    font-family: "SFPRO";
    font-size: 20px;
}

.jasakami {
    background-color: rgba(204, 204, 204, 0.5);
    padding: 30px;
    font-family: "SFPRO";
    font-size: 20px;
}

.galeri {
    background-color: rgba(204, 204, 204, 0.5);
    padding: 30px;
    font-family: "SFPRO";
    font-size: 20px;
}

.kontak {
    background-color: rgba(204, 204, 204, 0.5);
    padding: 30px;
    font-family: "SFPRO";
    font-size: 20px;
}

.title {
    padding-top: 20px;
    padding-bottom: 40px;
    color: #00308F;
}

.jasakamifot {
    padding-bottom: 30px;
    max-width: 500px;
}


.text {
    padding-bottom: 30px;
    font-family: sans-serif;
    font-size: 20px;
    text-indent: 50px;
    letter-spacing: 2px;
}

.text-jasakami-bawah {
    font-family: sans-serif;
    font-size: 20px;
    text-indent: 50px;
    letter-spacing: 2px;
}

.text-kontak {
    padding-bottom: 5px;
    font-family: sans-serif;
    font-size: 20px;
    text-indent: 50px;
    letter-spacing: 1px;
}

.text-kontak-bawah {
    padding-bottom: 20px;
    font-family: sans-serif;
    font-size: 20px;
    text-indent: 50px;
    letter-spacing: 1px;
}

@media (max-width: 600px) {
    .jasakamifot {
        padding-bottom: 30px;
        max-width: 300px;
    }
    
  }

@media (max-width: 333px) {
    .jasakamifot {
        padding-bottom: 30px;
        max-width: 200px;
    }
    
  }