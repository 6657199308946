@font-face {
    font-family: "SFPRO";
    src: url(../../fonts/SFPRODISPLAYREGULAR.OTF) format("truetype");
  }

.NavbarItems {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-family: "SFPRO";
}

.a-logo {
    margin-bottom: -4.5px;
}

.navbar-logo {
    background-color: rgba(204, 204, 204, 0.5);
    padding: 5px 25px 10px 25px;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;  
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: start;
}

.mobile-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: start;
}

.nav-links {
    border-radius: 20px;
    color: #00308F;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    color: aliceblue;
    transition: all 0.2s ease-out;
    text-shadow: 3px 3px darkgray;
}

.nav-links-mobile {
    display: none;
}

.mobile-links {
    border-radius: 20px;
    color: #00308F;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.mobile-links:hover {
    color: aliceblue;
    transition: all 0.2s ease-out;
    text-shadow: 3px 3px darkgray;
}

.mobile-links-mobile {
    display: none;
}


.menu-icon {
    display: none;
}

@media (min-width: 767px) {

}

@media (max-width: 767px) {
    /*(.nav-links {
        display: none;
    }*/
  }
  